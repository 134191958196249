img {
    height: auto;
    max-width: 100%;
}

.ot-image-box.st1,
.ot-image-box.st3 {
    margin-top: -30px;
}

.ot-image-box.v3 {
    /* margin-bottom: 12px; */
}

.ot-heading h2 {
    line-height: 48px;
    font-size: 35px;
}

.ot-image-box {
    display: block;
    position: relative;
    text-align: center;
    overflow: hidden;
    /* width: 95%; */
}

.ot-image-box .overlay {
    z-index: 1;
    opacity: 1;
    background: rgba(16, 120, 165, 0.2);
    /* background: rgba(238, 200, 64, 0.2); */
}

.ot-image-box h4 {
    color: #fff;
    position: absolute;
    top: 50%;
    /* left: 0; */
    width: 100%;
    font-size: 26px;
    transform: translateY(-50%);
}

.ot-image-box img {
    filter: grayscale(90%);
}

.ot-image-box:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
    z-index: 1;
    transition: all 0.3s linear;
    transform: rotate(45deg);
    background: #f5bb0c;
}

.ot-image-box:hover img {
    filter: none;
}

.ot-image-box:hover .overlay {
    background: transparent;
}

/* About */
.about-offer {
    padding-top: 120px;
    padding-bottom: 3rem;
    background-color: #0f0f0f;
}

.overlay {
    position: absolute;
    /* top: 0; */
    /* left: 0; */
    /* background: #fff; */
    /* opacity: 0.8; */
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.3s linear;
}

.video-popup.style-2 a {
    background-color: #f5bb0c;
    color: #fff;
}

.video-popup.style-2 a:hover {
    background-color: #43baff;
}

.style-2 .circle-1,
.style-2 .circle-2 {
    border-color: #f5bb0c;
}

.video-popup {
    display: flex;
    align-items: center;
}

.video-popup a {
    position: relative;
    width: 65px;
    height: 65px;
    /* display: inline-block; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    /* -webkit-border-radius: 50%; */
    /* -moz-border-radius: 50%; */
    text-align: center;
    line-height: 65px;
    color: #43baff;
}

.video-popup a i:before {
    font-size: 1.8rem;
}

/* .video-popup a:hover {
    background: #43baff;
    color: #fff;
} */

.video-popup a:hover span {
    border-color: #43baff;
}

.video-popup a span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.8);
    animation: circle-fade-before 2s infinite ease-in-out;
    border-radius: 50%;
}

.video-popup a .circle-2 {
    animation: circle-fade-after 2s infinite ease-in-out;
    -webkit-animation: circle-fade-after 2s infinite ease-in-out;
}

.video-popup>span {
    margin-left: 15px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    /* color: #1b1d21; */
    /* font-family: "Montserrat", sans-serif; */
    letter-spacing: 0.5px;
}

/* .video-popup .btn-inner {
    display: inline-block;
    border-radius: 50%;
} */

@keyframes circle-fade-before {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}

@keyframes circle-fade-after {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(2.5);
        opacity: 0;
    }
}







.home6-choose-section .choose-top .choose-title {
    padding-top: 80px;
    max-width: 450px;
    width: 100%;
    margin-left: 50px
}

@media(max-width:991px) {
    .home6-choose-section .choose-top .choose-title {
        padding-top: 0
    }
}

@media(max-width:767px) {
    .home6-choose-section .choose-top .choose-title {
        margin-left: 0
    }
}

.home6-choose-section .choose-top .choose-title span {
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: #f5bb0c;
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px
}

.home6-choose-section .choose-top .choose-title span::after {
    content: "";
    height: 1px;
    width: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f5bb0c;
}

.home6-choose-section .choose-top .choose-title h2 {
    font-weight: 700;
    font-size: 45px;
    line-height: 1.3;
    text-transform: capitalize;
    color: #fff
}

@media(max-width:1199px) {
    .home6-choose-section .choose-top .choose-title h2 {
        font-size: 35px
    }
}

@media(max-width:576px) {
    .home6-choose-section .choose-top .choose-title h2 {
        font-size: 30px
    }
}

.home6-choose-section .choose-top .choose-right-img {
    position: relative;
    z-index: 1
}

@media(max-width:991px) {
    .home6-choose-section .choose-top .choose-right-img img {
        width: 100%
    }
}

.home6-choose-section .choose-top .choose-right-img::after {
    content: "";
    background-color: rgba(30, 28, 34, .5);
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.home6-choose-section .choose-btm {
    padding-top: 50px
}

.home6-choose-section .choose-btm .choose-left-content {
    background-image: url(../../Assets/images/AboutUs/choose-bg.png), linear-gradient(#1e1c22, #1e1c22);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 65px 75px 65px 85px;
    position: relative;
    margin-right: 15px;
    margin-top: -120px
}

@media(max-width:1199px) {
    .home6-choose-section .choose-btm .choose-left-content {
        margin-top: 0;
        padding: 65px 30px
    }
}

@media(max-width:991px) {
    .home6-choose-section .choose-btm .choose-left-content {
        margin-right: 0
    }
}

.home6-choose-section .choose-btm .choose-left-content .icon {
    margin-bottom: 22px
}

.home6-choose-section .choose-btm .choose-left-content h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.46;
    letter-spacing: .03em;
    color: #e4e4e4;
    margin-bottom: 25px
}

.home6-choose-section .choose-btm .choose-left-content h4 span {
    color: #f5bb0c;
    font-size: 26px;
    font-weight: 600
}

@media(max-width:576px) {
    .home6-choose-section .choose-btm .choose-left-content h4 {
        font-size: 22px
    }

    .home6-choose-section .choose-btm .choose-left-content h4 span {
        font-size: 24px
    }
}

.home6-choose-section .choose-btm .choose-left-content p {
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    padding-left: 60px
}

@media(max-width:1199px) {
    .home6-choose-section .choose-btm .choose-left-content p {
        padding-left: 30px
    }
}

@media(max-width:576px) {
    .home6-choose-section .choose-btm .choose-left-content p {
        padding-left: 10px
    }
}

.home6-choose-section .choose-btm .choose-left-content .sl {
    padding-top: 65px;
    padding-left: 60px
}

@media(max-width:1199px) {
    .home6-choose-section .choose-btm .choose-left-content .sl {
        padding-left: 30px
    }
}

/* for mobile responsive css */
@media(max-width:576px) {
    .home6-choose-section .choose-btm .choose-left-content .sl {
        padding-left: 10px
    }

    .ot-image-box.st1,
    .ot-image-box.st3 {
        margin-top: 0px;
    }
}

.home6-choose-section .choose-btm .choose-left-content .sl h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 1;
    letter-spacing: .05em;
    color: rgba(228, 228, 228, .3)
}

.home6-choose-section .choose-btm .choose-left-content .about-btn {
    position: absolute;
    right: 30px;
    bottom: 20px
}

.home6-choose-section .choose-btm .choose-left-content .about-btn a {
    width: 123px;
    height: 123px;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: #fff;
    gap: 5px;
    transition: .7s
}

.home6-choose-section .choose-btm .choose-left-content .about-btn a svg {
    stroke: #fff;
    transition: .7s
}

.home6-choose-section .choose-btm .choose-left-content .about-btn a:hover {
    border-color: #f5bb0c;
    box-shadow: inset 0 0 0 10em #f5bb0c;
}

.home6-choose-section .choose-btm .choose-left-content .about-btn a:hover svg {
    transform: rotate(45deg)
}

.home6-choose-section .choose-btm .choose-left-content .choose-vec-top-r {
    position: absolute;
    bottom: 0;
    left: 0
}

.home6-choose-section .choose-btm .choose-left-content .choose-vec-btm-l {
    position: absolute;
    right: 0;
    top: 0
}

.home6-choose-section .choose-btm .choose-feature ul li {
    margin-bottom: 40px
}

.home6-choose-section .choose-btm .choose-feature ul li:last-child {
    margin-bottom: 0
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature {
    display: flex;
    align-items: center;
    gap: 25px
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .progress {
    height: 80px;
    min-width: 80x;
    max-width: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .2)
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .progress h3 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 30px;
    text-transform: capitalize;
    color: #fff;
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .content {
    /* max-width: 290px; */
    width: 100%
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .content h4 {
    /* font-weight: 600; */
    font-size: 25px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 8px
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .content p {
    font-weight: 300;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.sec-mar {
    background-color: #0f0f0f;
}