/* Content.css */


.content-new {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.content-new p {
    margin: 0.3rem;
}

.content-new p {
    font-size: 14px;
}

.content-new li {
    font-size: 14px;
}

/* .flex {
    display: flex;
}

.sidebar-new {
    position: sticky;
    top: 85px;
    align-self: flex-start;
    margin-left: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar-new a {
    display: block;
    padding: 8px;
    margin-bottom: 8px;
    color: #007bff;
    text-decoration: none;
}

.sidebar-new a:hover {
    text-decoration: underline !important;
} */

/* .content-new::-webkit-scrollbar {
    display: none !important;
} */