.help-center-footer {
    text-align: center;
    padding: 20px 0;
    font-size: 15px;
    color: #6c757d;
    background-color: #f8f9fa;
}

.help-center-footer a {
    color: #f5bb0c;
}