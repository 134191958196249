.header-wrapper {
    top: 0;
    z-index: 8;
    position: sticky;
    display: flex;
    height: 4rem;
    background-color: white;
    width: 100%;
}

.header__navbar {
    /* backdrop-filter: blur(8px); */
    width: 100%;
    background-color: var(--headerBackgroundColor);
    /* box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%); */
    box-shadow: 0 4px 40px rgba(0, 0, 0, .07);
    /* padding: 15px 0; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.header__navbar__left {
    display: flex;
    align-items: center;
}