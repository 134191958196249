/* Cookie Consent Styling */
.cookie-consent-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    width: 35%;
    /* Make the dialog smaller */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 20px;
    /* right: 20px; */
    left: 10px;
    /* Align the dialog to the right */
    z-index: 1000;
}

.cookie-text h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.cookie-text p {
    font-size: 0.9rem;
    line-height: 1.4;
    color: #333;
}

.cookie-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}

.cookie-actions .action-btn {
    padding: 5px 30px;
    /* Increase button size */
    font-size: 1rem;
    border: 1px solid #333;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
    /* Make buttons the same width */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.allow-btn:hover {
    background-color: #333;
    color: #fff;
}

.deny-btn:hover {
    background-color: #f5bb0c;
    color: #fff;
}

.details-link {
    font-size: 0.9rem;
    text-decoration: underline;
    color: #666;
    cursor: pointer;
    margin-top: 10px;
}

.details-link:hover {
    color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
    .cookie-consent-container {
        flex-direction: column;
        align-items: center;
        right: 10px;
        left: 10px;
        width: auto;
    }

    .cookie-text {
        text-align: center;
        margin-bottom: 20px;
    }

    .cookie-actions {
        margin-left: 0;
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    .action-btn {
        width: auto !important;
    }
}