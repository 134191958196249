.section-technology {
  background-color: #211E3B;
  background-image: url(../../Assets/images/technologies/bg-particle.png);
  background-position: 0px 70px;
  background-repeat: no-repeat;
  padding: 60px 0px 100px 0px;
}

.icon-main img {
  width: 70px;
}

.tech-box {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  /* border: 1px solid #f5bb0c; */
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  cursor: pointer;
}

.tech-box:hover {
  background: #43baff;
  border-color: #43baff;
  color: #fff;
}

.tech-box:hover h5 {
  color: #fff;
}

.icon-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
}

.tech-box h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;
}