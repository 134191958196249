.home-dark2 {
    background: #0f0f0f;
    padding: 6rem 0rem 5rem 0rem;
}

.service-details .about-content .section-title-5 {
    margin-bottom: 20px;
    color: #fff;
}

.service-details .about-content .section-title-5 h2 {
    font-size: 55px
}

@media(max-width:1199px) {
    .service-details .about-content .section-title-5 h2 {
        font-size: 40px
    }
}

@media(max-width:767px) {
    .service-details .about-content .section-title-5 h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .service-details .about-content .section-title-5 h2 {
        font-size: 30px
    }
}

.service-details .about-content p {
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 0
}

.service-details .about-content .about-featue {
    margin: 0;
    padding: 0;
    -moz-columns: 2;
    columns: 2
}

@media(max-width:576px) {
    .service-details .about-content .about-featue {
        -moz-columns: 1;
        columns: 1
    }
}

.service-details .about-content .about-featue li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: .02em;
    color: #d7d7d7;
    padding-top: 30px
}

.service-details .about-content .about-featue li svg {
    fill: #f5bb0c
}

.service-details .service-img img {
    border-radius: 5px
}

.service-details .how-we-work-section .section-title h2 {

    font-weight: 600;
    font-size: 40px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 0
}

.service-details .how-we-work-section .work-item {
    position: relative;
    padding: 0 100px;
    z-index: 1
}

@media(max-width:1500px) {
    .service-details .how-we-work-section .work-item {
        padding: 0 30px
    }
}

@media(max-width:991px) {
    .service-details .how-we-work-section .work-item {
        padding: 0
    }
}

.service-details .how-we-work-section .work-item::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, .1);
    position: absolute;
    left: 0;
    top: 82px;
    z-index: -1
}

@media(max-width:1199px) {
    .service-details .how-we-work-section .work-item::after {
        display: none;
        visibility: hidden
    }
}

.service-details .how-we-work-section .work-item .single-work {
    max-width: 280px;
    width: 100%
}

.service-details .how-we-work-section .work-item .single-work .work-icon {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #f5bb0c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px
}

.service-details .how-we-work-section .work-item .single-work .work-content h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 8px
}

@media(max-width:991px) {
    .service-details .how-we-work-section .work-item .single-work .work-content h3 {
        font-size: 24px
    }
}

@media(max-width:767px) {
    .service-details .how-we-work-section .work-item .single-work .work-content h3 {
        font-size: 22px
    }
}

.service-details .how-we-work-section .work-item .single-work .work-content p {
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.service-details .service-sort-driscription-area .driscription-img {
    margin-bottom: 40px
}

.service-details .service-sort-driscription-area .driscription-content p {
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.service-details .service-faq-area .faq-content-area h2 {

    font-weight: 600;
    font-size: 40px;
    line-height: 1.5;
    text-transform: capitalize;
    color: #fff
}

@media(max-width:767px) {
    .service-details .service-faq-area .faq-content-area h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .service-details .service-faq-area .faq-content-area h2 {
        font-size: 30px
    }
}

.service-details .service-faq-area .accordion .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(238, 238, 238, .2);
    border-radius: 0
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button {
    background-color: transparent;
    font-weight: 400;
    font-size: 17px;
    text-transform: capitalize;
    color: #b5b5b5;
    padding: 25px 0 20px
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button:not(.collapsed) {
    color: #f5bb0c;
    border-bottom: 1px solid rgba(238, 238, 238, .2)
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button:not(.collapsed):focus {
    box-shadow: none;
    border: none
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: unset;
    font-family: primeicons;
    content: "\e90f";
    font-size: 12px;
    color: var(--title-color)
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
    border: none
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button::after {
    flex-shrink: 0;
    width: unset;
    height: unset;
    margin-left: auto;
    content: "\e90d";
    font-weight: 600;
    font-size: 12px;
    font-family: primeicons;
    color: rgba(255, 255, 255, .5);
    background-image: none;
    background-size: contain;
    transition: transform .2s ease-in-out
}

.service-details .service-faq-area .accordion .accordion-item .accordion-body {
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    padding: 20px 20px 30px
}