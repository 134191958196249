.parallax-content {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}

.section {
  padding: 50px 20px;
  text-align: center;
}

.section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.section p {
  font-size: 1.2em;
}

.section ul {
  list-style: none;
  padding: 0;
}

.section ul li {
  font-size: 1.2em;
  margin: 10px 0;
}

/* style for scroll to top button  */
.scroll-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title-4.two {
  max-width: 620px;
  width: 100%;
  margin: 0 auto 55px
}

.section-title-4>span {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: #f5bb0c;
  display: inline-block;
  margin-bottom: 10px
}

.section-title-4 h2 {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 42px;
  line-height: 1.4;
  letter-spacing: .03em;
  text-transform: capitalize;
  color: white
}