.sec-mar {
    padding: 120px 0;
}

.faq-page .faq-items ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border: none;
    flex-direction: column
}

.faq-page .faq-items ul li {
    margin-bottom: 15px
}

.faq-page .faq-items ul li .nav-link {
    border-radius: 0;
    border: none;
    display: block;
    width: 100%;
    text-align: start;
    padding: 15px 25px;
    font-size: 14px;
    font-weight: 500;
    color: #FFF;
    background-image: url(../../Assets/images/contactUs/pricing-bg.png), linear-gradient(#161A1E, #161A1E);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    text-align: center
}

.faq-page .faq-items ul li .nav-link.active {
    color: #f5bb0c
}