.header-area2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    transition: all .8s ease-out 0s;
    border-bottom: 1px solid rgba(255, 255, 255, .1)
}

/* @media(max-width:1700px) {
    .header-area2 {
        padding: 0 7%
    }
}

@media(max-width:1500px) {
    .header-area2 {
        padding: 0 3%
    }
} */

@media(max-width:1500px) {
    .header-area2.one {
        padding: 0 7%
    }
}

.header-area2.style-2 {
    padding: 0 5%
}

@media(max-width:1399px) {
    .header-area2.style-2 {
        padding: 0 3%
    }
}

.header-area2.style-2 .nav-right {
    gap: 40px
}

@media(max-width:1399px) {
    .header-area2.style-2 .nav-right {
        gap: 25px
    }
}

.header-area2.style-2 .header-btn a {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .03em;
    color: #f5bb0c;
    border: 1px solid #f5bb0c;
    border-radius: 5px;
    padding: 10px 35px;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.header-area2.style-2 .header-btn a::after {
    position: absolute;
    content: "";
    display: block;
    left: 15%;
    right: -20%;
    top: -4%;
    height: 150%;
    width: 150%;
    bottom: 0;
    background-color: #f5bb0c;
    transform: skewX(45deg) scale(0, 1);
    z-index: -1;
    transition: all .5s ease-out 0s
}

.header-area2.style-2 .header-btn a:hover {
    color: #1D1D1D
}

.header-area2.style-2 .header-btn a:hover::after {
    transform: skewX(45deg) scale(1, 1)
}

.header-area2.style-2 .header-logo {
    padding: 25px 0
}

@media(max-width:1199px) {
    .header-area2.style-2 .header-contact {
        display: none;
        visibility: hidden
    }
}

.header-area2.style-2 .header-contact span {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .03em;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px
}

.header-area2.style-2 .header-contact h6 {
    margin-bottom: 0
}

.header-area2.style-2 .header-contact h6 a {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .03em;
    color: #fff;
    transition: .5s
}

.header-area2.style-2 .header-contact h6 a:hover {
    color: #f5bb0c
}

.header-area2.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    box-sizing: border-box;
    background-color: #08090b;
    z-index: 99
}

@keyframes smooth-header {
    0% {
        transform: translateY(-30px)
    }

    100% {
        transform: translateY(0px)
    }
}

.header-area2 .header-logo {
    padding: 15px 0
}

.header-area2 .menu-close-btn i {
    color: #fff
}

.header-area2 .mobile-menu-form .hotline,
.header-area2 .mobile-menu-form .email {
    display: flex;
    align-items: center
}

.header-area2 .mobile-menu-form .hotline .hotline-icon svg,
.header-area2 .mobile-menu-form .hotline .email-icon svg,
.header-area2 .mobile-menu-form .email .hotline-icon svg,
.header-area2 .mobile-menu-form .email .email-icon svg {
    fill: #f5bb0c
}

.header-area2 .mobile-menu-form .hotline .hotline-info,
.header-area2 .mobile-menu-form .hotline .email-info,
.header-area2 .mobile-menu-form .email .hotline-info,
.header-area2 .mobile-menu-form .email .email-info {
    margin-left: 12px
}

.header-area2 .mobile-menu-form .hotline .hotline-info span,
.header-area2 .mobile-menu-form .hotline .email-info span,
.header-area2 .mobile-menu-form .email .hotline-info span,
.header-area2 .mobile-menu-form .email .email-info span {
    font-size: 12px;
    font-weight: 400;
    color: #f5bb0c
}

.header-area2 .mobile-menu-form .hotline .hotline-info h6,
.header-area2 .mobile-menu-form .hotline .email-info h6,
.header-area2 .mobile-menu-form .email .hotline-info h6,
.header-area2 .mobile-menu-form .email .email-info h6 {
    margin-bottom: 0
}

.header-area2 .mobile-menu-form .hotline .hotline-info h6 a,
.header-area2 .mobile-menu-form .hotline .email-info h6 a,
.header-area2 .mobile-menu-form .email .hotline-info h6 a,
.header-area2 .mobile-menu-form .email .email-info h6 a {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    transition: .35s
}

.header-area2 .mobile-menu-form .hotline .hotline-info h6 a:hover,
.header-area2 .mobile-menu-form .hotline .email-info h6 a:hover,
.header-area2 .mobile-menu-form .email .hotline-info h6 a:hover,
.header-area2 .mobile-menu-form .email .email-info h6 a:hover {
    color: #f5bb0c
}

.header-area2 .header-btn5 {
    margin-top: 50px;
    width: 100%
}

.header-area2 .header-btn5 a {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 11px 26px
}

.header-area2 .nav-right {
    gap: 30px
}

@media(max-width:991px) {
    .header-area2 .nav-right {
        gap: 0
    }
}

.header-area2 .nav-right .header-btn .primary-btn3 {
    padding: 10px 26px;
}

.header-area2 .nav-right .sidebar-btn2 {
    height: 36px;
    width: 36px;
    border-radius: 15px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .35s
}

.header-area2 .nav-right .sidebar-btn2:hover {
    background-color: #f5bb0c;
    border-color: #f5bb0c
}

.header-area2 .nav-right .mobile-menu-btn {
    display: none;
    visibility: hidden
}

@media(max-width:991px) {
    .header-area2 .nav-right .mobile-menu-btn {
        display: flex;
        margin-left: 50px;
        flex-direction: column;
        align-items: end;
        visibility: visible;
        justify-content: center;
        position: relative
    }

    .header-area2 .nav-right .mobile-menu-btn span {
        height: 3px;
        width: 25px;
        background-color: #fff;
        display: flex;
        transition: transform .5s ease-in;
        position: absolute;
        top: 0
    }

    .header-area2 .nav-right .mobile-menu-btn span::before {
        transition-duration: .5s;
        position: absolute;
        width: 35px;
        height: 3px;
        background-color: #fff;
        content: "";
        top: -10px;
        right: 0
    }

    .header-area2 .nav-right .mobile-menu-btn span::after {
        transition-duration: .5s;
        position: absolute;
        width: 15px;
        height: 3px;
        right: 0;
        background-color: #fff;
        content: "";
        top: 10px
    }

    .header-area2 .nav-right .mobile-menu-btn.active span {
        background-color: transparent
    }

    .header-area2 .nav-right .mobile-menu-btn.active span::before {
        transform: rotateZ(45deg) translate(8px, 6px);
        width: 35px
    }

    .header-area2 .nav-right .mobile-menu-btn.active span::after {
        transform: rotateZ(-45deg) translate(8px, -6px);
        width: 35px
    }
}

.header-area2 .main-menu {
    display: inline-block
}

.header-area2 .main-menu .mobile-menu-logo {
    display: none
}

.header-area2 .main-menu ul {
    margin: 0;
    padding: 0
}

.header-area2 .main-menu ul>li {
    display: inline-block;
    position: relative;
    padding: 0 8px
}

.header-area2 .main-menu ul>li a {
    color: #fff;
    display: block;
    text-transform: capitalize;
    padding: 32px 20px;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    transition: all .5s ease-out 0s;
    position: relative
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li a {
        padding: 32px 9px
    }
}

.header-area2 .main-menu ul>li i {
    font-size: 20px;
    text-align: center;
    color: #fff;
    font-style: normal;
    position: absolute;
    right: -5px;
    top: 35px;
    z-index: 999;
    cursor: pointer;
    display: none;
    transition: all .5s ease-out 0s;
    opacity: 0
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li i {
        opacity: 1
    }
}

.header-area2 .main-menu ul>li i.active {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li i.active::before {
    content: "\f2ea"
}

.header-area2 .main-menu ul>li ul.sub-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    margin: 0;
    display: none;
    min-width: 215px;
    background: #fff;
    box-shadow: 0 30px 80px rgba(8, 0, 42, .08);
    text-align: left
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li ul.sub-menu {
        box-shadow: none
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li {
    padding: 0;
    display: block;
    position: relative
}

.header-area2 .main-menu ul>li ul.sub-menu>li i {
    position: absolute;
    top: 10px;
    right: 6px;
    display: block;
    color: #1D1D1D;
    font-size: 20px
}

.header-area2 .main-menu ul>li ul.sub-menu>li .dropdown-icon {
    color: #1D1D1D;
    opacity: 1;
    top: 13px;
    font-size: 13px;
    right: 15px
}

@media(max-width:991px) {
    .header-area2 .main-menu ul>li ul.sub-menu>li .dropdown-icon {
        right: 0;
        color: #fff;
        top: 8px;
        font-size: 20px
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li .dropdown-icon2 {
    color: #1D1D1D;
    opacity: 1;
    top: 14px;
    right: 10px;
    font-size: 13px
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li ul.sub-menu>li .dropdown-icon2::before {
        content: "\f4fe"
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li a {
    display: block;
    padding: 14px 20px;
    color: #1D1D1D;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 1;
    transition: all .4s ease-out 0s;
    position: relative;
    border-bottom: 1px solid #ddd
}

.header-area2 .main-menu ul>li ul.sub-menu>li a::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    background: linear-gradient(90deg, #fff, #f5bb0c, #fff);
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    transform-origin: left;
    transition: .4s ease-in;
    z-index: 1
}

@media(max-width:991px) {
    .header-area2 .main-menu ul>li ul.sub-menu>li a {
        border-color: #525252
    }

    .header-area2 .main-menu ul>li ul.sub-menu>li a::before {
        background: linear-gradient(90deg, #525252, #f5bb0c, #525252)
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li a:hover {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li ul.sub-menu>li a:hover::before {
    transform: scale(1)
}

.header-area2 .main-menu ul>li ul.sub-menu>li a.active {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li ul.sub-menu>li a.active::before {
    transform: scale(1)
}

.header-area2 .main-menu ul>li ul.sub-menu>li:last-child a {
    border-bottom: none
}

.header-area2 .main-menu ul>li ul.sub-menu>li:last-child a::before {
    display: none
}

.header-area2 .main-menu ul>li ul.sub-menu>li:hover>a {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li ul.sub-menu>li:hover .dropdown-icon {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li ul.sub-menu>li:hover .dropdown-icon2 {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li ul.sub-menu>li.active>a {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li ul.sub-menu>li.active .dropdown-icon {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li ul.sub-menu>li.active .dropdown-icon2 {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li ul.sub-menu>li .sub-menu {
    left: 100%;
    position: absolute;
    max-width: 230px;
    min-width: 215px;
    background: #fff;
    box-shadow: 0 30px 80px rgba(8, 0, 42, .08);
    top: 0
}

.header-area2 .main-menu ul>li ul.sub-menu>li .sub-menu::before {
    display: none;
    visibility: hidden
}

@media only screen and (max-width:991px) {
    .header-area2 .main-menu ul>li ul.sub-menu>li .sub-menu {
        margin-left: 10px;
        position: unset;
        max-width: 230px;
        min-width: 215px;
        background: 0 0;
        top: 0
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li .sub-menu>li i {
    display: block
}

.header-area2 .main-menu ul>li ul.sub-menu>li:last-child {
    border-bottom: none
}

.header-area2 .main-menu ul>li.menu-item-has-children::after {
    content: "\e930";
    font-family: primeicons;
    font-weight: 0;
    position: absolute;
    top: 35px;
    right: 12px;
    font-size: 11px;
    color: #fff;
    transition: all .55s ease-in-out
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li.menu-item-has-children::after {
        right: 2px
    }
}

@media(max-width:991px) {
    .header-area2 .main-menu ul>li.menu-item-has-children::after {
        display: none;
        visibility: hidden
    }
}

@media(min-width:1200px) {
    .header-area2 .main-menu ul>li:hover>ul.sub-menu {
        display: block;
        animation: fade-up .45s linear
    }
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(10px);
        visibility: hidden
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible
    }
}

.header-area2 .main-menu ul>li:hover>a {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li:hover::after {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li:hover .dropdown-icon2 {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li.active>a {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li.active::after {
    color: #f5bb0c
}

.header-area2 .main-menu ul>li.active .dropdown-icon2 {
    color: #f5bb0c
}

.header-area2 .main-menu ul li.menu-item-has-children>i {
    display: block
}

@media only screen and (max-width:991px) {
    .header-area2 .mobile-logo-area {
        justify-content: center !important
    }

    .header-area2 .dropdown-icon {
        color: #fff
    }

    .header-area2 .main-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 280px;
        padding: 30px 20px !important;
        z-index: 99999;
        height: 100%;
        overflow: auto;
        background: #0a1019;
        transform: translateX(-100%);
        transition: transform .3s ease-in;
        box-shadow: 0 2px 20px rgba(0, 0, 0, .03)
    }

    .header-area2 .main-menu.show-menu {
        transform: translateX(0)
    }

    .header-area2 .main-menu .mobile-menu-logo {
        text-align: left;
        padding-top: 20px;
        display: block;
        padding-bottom: 8px
    }

    .header-area2 .main-menu .menu-list {
        padding-top: 50px;
        padding-bottom: 30px
    }

    .header-area2 .main-menu ul {
        float: none;
        text-align: left;
        padding: 5px 0 20px 0
    }

    .header-area2 .main-menu ul li {
        display: block;
        position: relative;
        padding: 0 5px
    }

    .header-area2 .main-menu ul li i {
        display: block
    }

    .header-area2 .main-menu ul li a {
        padding: 10px 0;
        display: block;
        font-size: 1rem
    }

    .header-area2 .main-menu ul li ul.sub-menu {
        position: static;
        min-width: 200px;
        background: 0 0;
        border: none;
        opacity: 1;
        visibility: visible;
        box-shadow: none;
        transform: none;
        transition: none;
        display: none;
        margin-top: 0 !important;
        transform: translateY(0px);
        margin-left: 10px
    }

    .header-area2 .main-menu ul li ul.sub-menu>li {
        border-bottom: 1px solid transparent
    }

    .header-area2 .main-menu ul li ul.sub-menu>li a {
        color: #fff;
        font-size: 14px;
        padding: 12px 0
    }

    .header-area2 .main-menu ul li ul.sub-menu>li a:hover {
        color: #f5bb0c;
        margin-left: 10px
    }

    .header-area2 .main-menu ul li ul.sub-menu>li a.active {
        color: #f5bb0c
    }

    .header-area2 .main-menu ul li ul.sub-menu>li i {
        color: #f5bb0c;
        right: -13px
    }

    .header-area2 .main-menu ul li .pi {
        top: 8px;
        font-size: 20px;
        color: #fff
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .header-area2.two {
        padding: 0 3%
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .header-area2.two .main-menu ul>li a {
        padding: 32px 14px
    }
}

@media(max-width:1399px) {
    .header-area2.two .main-menu ul>li a {
        padding: 32px 8px
    }
}

@media(max-width:991px) {
    .header-area2.two .main-menu ul>li a {
        padding: 10px 0
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .header-area2.two .main-menu ul>li.menu-item-has-children::after {
        right: 6px
    }
}

@media(max-width:1399px) {
    .header-area2.two .main-menu ul>li.menu-item-has-children::after {
        right: 1px
    }
}




.header-sidebar {
    max-width: 420px;
    background: #1D1D1D;
    padding: 60px 25px 0;
    position: fixed;
    min-height: 100vh;
    height: 100%;
    right: 0;
    z-index: 999;
    top: auto;
    overflow-y: scroll;
    border-left: 1px solid rgba(255, 255, 255, .3);
    transition: all .6s ease 0s;
    transform: translateX(100%)
}

@media(max-width:576px) {
    .header-sidebar {
        max-width: 320px
    }
}

.header-sidebar::-webkit-scrollbar {
    width: 1px
}

.header-sidebar.slide {
    transform: translate(0)
}

.header-sidebar .siderbar-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px
}

.header-sidebar .siderbar-top .close-btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.header-sidebar .siderbar-top .close-btn i {
    font-size: 16px;
    color: #ddd
}

.header-sidebar .sidebar-content {
    padding: 30px 0
}

.header-sidebar .sidebar-content p {
    font-size: 18px;
    margin-bottom: 0;
    color: #fff
}

.header-sidebar .address-card {
    position: relative;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden
}

.header-sidebar .address-card .content {
    background: #1D1D1D;
    box-shadow: 3px 4px 30px rgba(136, 136, 136, .1);
    padding: 30px 0;
    z-index: 2;
    position: relative;
    transition: all .5s ease
}

.header-sidebar .address-card .content .header {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px
}

.header-sidebar .address-card .content .header h3 {
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
    transition: all .5s ease
}

.header-sidebar .address-card .content .header .location {
    border: 1px solid #f5bb0c;
    width: 2.8rem;
    height: 2.8rem;
    line-height: 2.8rem;
    border-radius: 50%;
    text-align: center;
    margin-right: 15px;
    background-color: transparent
}

.header-sidebar .address-card .content .header .location svg {
    fill: #f5bb0c;
    transition: all .5s ease
}

.header-sidebar .address-card .address-list {
    margin: 0;
    padding: 0
}

.header-sidebar .address-card .address-list li {
    font-size: 1rem;
    color: #fff;
    font-weight: 400;
    line-height: 1.7;
    margin-bottom: 8px;
    transition: all .5s ease
}

.header-sidebar .address-card .address-list li span {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    transition: all .5s ease;
    margin-right: 10px
}

.header-sidebar .address-card .address-list li a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    transition: all .5s ease
}

.header-sidebar .address-card .address-list li a:hover {
    color: #f5bb0c
}

.header-sidebar .informations .single-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 15px
}

.header-sidebar .informations .single-info .icon {
    height: 2.8rem;
    min-width: 2.8rem;
    border-radius: 50%;
    border: 1px solid #f5bb0c;
    color: #f5bb0c;
    display: flex;
    align-items: center;
    justify-content: center
}

.header-sidebar .informations .single-info .icon i {
    font-size: 20px
}

.header-sidebar .informations .single-info .info a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    display: block;
    transition: all .5s ease
}

.header-sidebar .informations .single-info .info a:hover {
    color: #f5bb0c
}

.header-sidebar .informations .single-info .info p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    display: block;
    transition: all .5s ease
}

.header-sidebar .follow-area {
    padding: 30px 0
}

.header-sidebar .follow-area h5 {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    position: relative;
    margin-bottom: 0
}

.header-sidebar .follow-area .para {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    font-family: poppins, sans-serif;
    line-height: 28px;
    margin-bottom: 10px
}

.header-sidebar .follow-area .blog-widget-body .follow-list {
    margin: 0;
    padding: 10px 0 0
}

.header-sidebar .follow-area .blog-widget-body .follow-list li a {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    background: 0 0;
    border-radius: 3px;
    font-size: 18px;
    color: #fff;
    transition: all .35s ease-in-out
}

.header-sidebar .follow-area .blog-widget-body .follow-list li a:hover {
    background-color: #f5bb0c;
    border-color: #f5bb0c
}

.primary-btn3 {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .03em;
    color: #1D1D1D;
    border-radius: 5px;
    background-color: #f5bb0c;
    padding: 14px 26px;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.primary-btn3::after {
    position: absolute;
    content: "";
    display: block;
    left: 15%;
    right: -20%;
    top: -4%;
    height: 150%;
    width: 150%;
    bottom: 0;
    border-radius: 2px;
    background-color: #0a1019;
    transform: skewX(45deg) scale(0, 1);
    z-index: -1;
    transition: all .5s ease-out 0s
}

.primary-btn3:hover {
    color: #fff
}

.primary-btn3:hover::after {
    transform: skewX(45deg) scale(1, 1)
}

/* Show nested submenu on hover */
.menu-item-has-children:hover .sub-menu .sub-menu-nested {
    display: block;
}