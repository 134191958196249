/* span {
    color: #f5bb0c;
} */

.section-title {
    color: white;
    margin-bottom: 30px;
}

.btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}

.secondary-btn {
    background: #f5bb0c;
    border-radius: 40px;
    color: #002332;
    padding: 13px 25px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
}

.secondary-btn:hover {
    background-color: #6FD943;
}

.try-now {
    background-color: #6FD943;
    color: #022332;
    padding: 13px 25px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 40px;
    text-transform: capitalize;
}

.try-now:hover {
    background-color: #6FD943;
    -webkit-box-shadow: 0px 5px 18px rgb(111 217 67 / 50%) !important;
    box-shadow: 0px 5px 18px rgb(111 217 67 / 50%) !important;
}

.product-banner-section {
    /* padding: 100px 0 70px; */
    background: #002332;
    overflow: hidden;
}

.product-banner-section .btn-wrapper a {
    font-weight: 600;
}

.product-banner-section .right-side-wrapper .img-wrapper {
    padding-top: 10%;
    z-index: 1;
}

.product-banner-section .right-side-wrapper .img-wrapper img {
    height: 100%;
    width: 100%;
}

.product-banner-section .right-side-wrapper {
    position: relative;
    z-index: 1;
}

.product-banner-section .video-right-wrp {
    position: relative;
    padding-top: 50%;
    z-index: 1;
}

.product-banner-section .title-content-inner {
    position: relative;
    z-index: 1;
}

.product-banner-section .video-right-wrp video {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
}

.product-banner-section .video-right-wrp .play-vid {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.product-banner-section .video-right-wrp .play-vid i {
    color: #f5bb0c;
    font-size: 60px
}

.product-banner-section .image-rings {
    position: absolute;
    max-width: 597.69px;
    width: 100%;
    height: 597.69px;
    margin: auto;
    top: 23px;
    left: 80px;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.product-banner-section .image-rings::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 363.48px;
    height: 363.48px;
    background: -o-radial-gradient(50% 50%, 50% 50%, #f5bb0c 0%, rgba(111, 217, 67, 0) 100%);
    background: radial-gradient(50% 50% at 50% 50%, #f5bb0c 0%, rgba(111, 217, 67, 0) 100%);
}

.product-banner-section .image-rings .ring {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;
    border-radius: 100%;
    width: 478px;
    height: 478px;
    -webkit-transform: scale(.1, .1);
    -ms-transform: scale(.1, .1);
    transform: scale(.1, .1);
    border: 0.829861px solid #f5bb0c;
}

.product-banner-section .image-rings .ring-0 {
    -webkit-animation: ring-1 2s 0s ease-out infinite;
    animation: ring-1 2s 0s ease-out infinite;
}

.product-banner-section .image-rings .ring-1 {
    -webkit-animation: ring-1 2s .3s ease-out infinite;
    animation: ring-1 2s .3s ease-out infinite;
}

.product-banner-section .image-rings .ring-2 {
    -webkit-animation: ring-1 2s .6s ease-out infinite;
    animation: ring-1 2s .6s ease-out infinite;
}

.product-banner-section .image-rings .ring-3 {
    -webkit-animation: ring-1 2s .9s ease-out infinite;
    animation: ring-1 2s .9s ease-out infinite;
}

@-webkit-keyframes ring-1 {
    0% {
        -webkit-transform: scale(.1, .1);
        transform: scale(.1, .1);
        opacity: 1;
    }

    50% {
        opacity: 0.53;
    }

    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes ring-1 {
    0% {
        -webkit-transform: scale(.1, .1);
        transform: scale(.1, .1);
        opacity: 1;
    }

    50% {
        opacity: 0.53;
    }

    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
    }
}

/* src/components/IconCard.css */
.icon-card {
    text-align: center;
    width: 50px;
    background-color: #f5bb0c;
    border-radius: 8px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-card .icon img {
    width: 50px;
    height: 50px;
}

.title {
    margin-top: 10px;
    font-size: 16px;
    color: #FFF;
}

.ftu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #013D29;
}

.icon-cards-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width:767px) {
    .product-banner-section .image-rings::after {
        width: 234.62px;
        height: 234.62px;
    }

    .product-banner-section .image-rings .ring {
        width: 250px;
        height: 250px;
    }

    .product-banner-section .title-content-inner {
        margin: 30px auto 0;
        text-align: center;
        max-width: 100%;
    }

    .btn-wrapper {
        justify-content: center;
    }

    .is-mobile {
        flex-direction: column-reverse;
    }

    .listing-slider-section .left-wrp {
        border-right: 0 !important;
    }
}

.product-detail-section {
    background-color: #022332;
}

.product-detail-section .left-inner .secondary-btn {
    padding: 12px 48px;
}

.price {
    font-weight: 600;
    color: #fff !important;
    font-size: 31.4831px;
}

.prd-description {
    font-weight: 800;
    font-size: 12px;
    line-height: 176%;
}

.product-detail-section .users-rating p {
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 140%;
}

.product-detail-section .secondary-btn {
    font-size: 14px;
    justify-content: center;
    padding: 8px 15px;
}




/* slider section */
.listing-slider-section {
    background-color: #0f0f0f;
    color: white;
}

.listing-slider-section .right-wrp h3 {
    margin-bottom: 20px;
}

.listing-slider-section .right-wrp ul li {
    text-indent: -25px;
    margin-left: 25px;
    list-style-type: disc;
    list-style-position: inside;
    font-size: 18px;
    font-weight: 400;
}

.listing-slider-section .right-wrp ul li:not(:last-of-type) {
    margin-bottom: 24px;
}

.listing-slider-section .left-wrp {
    border-right: 1px solid #f5bb0c;
}





.overline-title {
    padding: 0px;
    font-size: .875rem;
    line-height: 1rem;
    display: inline-block;
    letter-spacing: .04rem;
    color: #f5bb0c;
    text-transform: uppercase;
    font-weight: 600;
}

.section-space {
    padding: 60px 0
}

.media {
    flex-shrink: 0;
    width: 3.25rem;
    height: 3.25rem;
    overflow: hidden;
    color: #0a1425;
    font-size: 1.0833333333rem;
    border-radius: .5rem
}

.media-middle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.media-circle {
    border-radius: 50rem !important;
}

.media-lg {
    z-index: 1;
    width: 4rem;
    height: 4rem;
    font-size: 1.3333333333rem;
}



.p-card {
    text-align: center;
}

.p-card .pi {
    display: block;
    margin-bottom: 1rem;
}

.p-text-center {
    text-align: center;
}

.p-m-6 {
    margin: 2rem;
}

.p-p-3 {
    padding: 1rem;
}


.banner {
    background-color: #0d1b2a;
    color: #fff;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}

.banner-content {
    max-width: 500px;
    z-index: 1;
}

.banner-content h5 {
    color: #f5bb0c;
    font-size: 14px;
    margin-bottom: 10px;
}

.banner-content h1 {
    font-size: 36px;
    margin-bottom: 20px;
    /* color: #f5bb0c; */
}

.banner-content p {
    font-size: 16px;
    /* color: #6FD943; */
    margin-bottom: 20px;
}

.view-now-btn {
    background-color: #f5bb0c;
    color: #fff;
    /* font-weight: 500; */
    padding: 6px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
}

.banner-image {
    position: absolute;
    top: auto;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    animation: slideIn 2s forwards;
    transform: translateX(10%);
}

.banner-image img {
    width: 100%;
    border-radius: 10px;
}

@keyframes slideIn {
    0% {
        right: -150%;
    }

    100% {
        right: 0;
    }
}

@media (max-width: 768px) {
    .banner {
        padding: 20px;
        flex-direction: column;
    }

    .banner-content {
        max-width: 100%;
        text-align: center;
    }

    .banner-content h1 {
        font-size: 28px;
    }

    .banner-content p {
        font-size: 14px;
    }

    .view-now-btn {
        font-size: 14px;
        padding: 8px 16px;
    }

    .banner-image {
        position: relative;
        height: auto;
        transform: translateX(0);
        animation: none;
        margin-top: 20px;
    }

    .banner-image img {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }
}





























.application-icon {
    position: relative;
    overflow: initial;
}

.application-icon .badge {
    position: absolute;
    top: 0;
    right: -14px;
    z-index: 9999999;
    font-size: 10px;
    padding: 4px 8px;
}

.application-icon .badge-green {
    background-color: #22C55E !important;
    color: rgb(255 255 255) !important;
}

.text-bg-yellow-300 {
    background-color: #f5bb0c15;
}

/* .text-bg-purple-300 {
    background-color: #e0cafc;
}

.text-bg-gray-300 {
    background-color: #c9c9d4;
}

.text-bg-cyan-300 {
    background-color: #c0f1fc;
}

.text-bg-violet-300 {
    background-color: #c4caf2;
}

.text-bg-green-300 {
    background-color: #bee6d4;
}

.text-bg-red-300 {
    background-color: #ffc7cb;
}

.text-bg-orange-300 {
    background-color: #fcd6c7;
} */

.blink_me {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.2;
    }
}

.media-xl {
    z-index: 1;
    width: 4.625rem;
    height: 4.625rem;
    font-size: 1.875rem;
    border-radius: 1.5rem;
}


.nk-feature-block-content {
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1.7rem;
    /* transition: all 500ms; */
    cursor: pointer;
}

.nk-feature-block-content:hover {
    border: 1px solid #f5bb0c;
}

/* .nk-feature-block-content:hover {
    background: #FFF;
    box-shadow: 0 4px 24px 0 rgba(211, 218, 233, 0.5);
} */

.nk-integrate-section {
    background-color: #f3f4ff;
    padding: 60px 0;
}

.media:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
}

/* .text-bg-primary-soft {
    color: #2B5CFD !important;
    background-color: #e6ebff !important;
    border-color: transparent
} */

/* .text-bg-info-soft {
    color: #13D5FF !important;
    background-color: #e3faff !important;
    border-color: transparent
} */

/* .text-bg-warning-soft {
    color: #FDB52A !important;
    background-color: #fff6e5 !important;
    border-color: transparent
} */

/* .text-bg-success-soft {
    color: #22C55E !important;
    background-color: #e4f8ec !important;
    border-color: transparent
} */

/* .text-bg-layout-primary-soft {
    color: #2B5CFD !important;
    background-color: #e6ebff !important;
    border-color: transparent
} */

/* .text-bg-danger-soft {
    color: #FA5B68 !important;
    background-color: #feebed !important;
    border-color: transparent
} */

/* new faq design */
.faq-page .accordion .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(238, 238, 238, .2);
    border-radius: 0
}

.faq-page .accordion .accordion-item .accordion-button {
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    color: #fff;
    padding: 25px 0 20px
}

.faq-page .accordion .accordion-item .accordion-button:not(.collapsed) {
    color: #f5bb0c;
    border-bottom: 1px solid rgba(238, 238, 238, .2)
}

.faq-page .accordion .accordion-item .accordion-button:not(.collapsed):focus {
    box-shadow: none;
    border: none
}

.faq-page .accordion .accordion-item .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: unset;
    font-family: primeicons !important;
    content: "\e90f";
    font-size: 12px;
    color: var(--title-color)
}

.faq-page .accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
    border: none
}

.faq-page .accordion .accordion-item .accordion-button::after {
    flex-shrink: 0;
    width: unset;
    height: unset;
    margin-left: auto;
    content: "\e90d";
    font-weight: 600;
    font-size: 12px;
    font-family: primeicons !important;
    color: rgba(255, 255, 255, .5);
    background-image: none;
    background-size: contain;
    transition: transform .2s ease-in-out
}

.faq-page .accordion .accordion-item .accordion-body {
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    padding: 20px 20px 30px
}


.card:hover {
    border: 1px solid #f5bb0c;
}












.integrations-container {
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.title-new {
    color: #0047ab;
    /* font-size: 2.5rem; */
    /* margin-bottom: 10px; */
}

.description {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #333;
}

.nk-block-head-content ul {
    list-style: none;
    padding: 0;
}

.nk-block-head-content ul li::before {
    content: "✔️";
    display: flex;
    /* Add checkmark or use an icon */
    /* position: absolute; */
    /* left: 0; */
    /* color: #1a73e8; */
}



/* Css for Why Smart HelpDesk? */

.feature-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.feature-description {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 21px;
    color: #545454;
}

.video-icon-overlay {
    position: absolute;
    top: 67.5%;
    left: 67.5%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.video-icon-overlay .play-icon {
    transition: transform 0.3s;
    /* color: #FDB52A; */
    font-size: 1.3rem;
}

.video-icon-overlay:hover .play-icon {
    transform: scale(1.2);
}