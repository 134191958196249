/* primereact style override */

/* header megamenu hamburger icon right side */
.p-megamenu-button {
    margin-left: auto;
}

.p-megamenu-panel {
    margin-top: 12px;
}

/* style for header transparent */
.p-megamenu {
    background: transparent;
    border: none;
}