footer {
    background-image: url(../../Assets/images/footer/footer-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden
}

/* footer.two {
    background-image: url(../../Assets/images/footer/footer-bg.png), linear-gradient(#1d2125, #1d2125)
} */

/* @media(max-width:767px) {
    footer.two {
        background-image: url(../../Assets/images/footer/home5-services-bg.png), linear-gradient(#171717, #171717)
    }
} */

/* footer.three {
    background-image: url(../../Assets/images/footer/footer-bg.png), linear-gradient(#171717, #171717)
} */

/* @media(min-width:1400px) and (max-width:1599px) {
    footer.three {
        padding-left: 70px;
        padding-right: 70px
    }
} */

/* @media(max-width:767px) {
    footer.three {
        background-image: url(../../Assets/images/footer/home5-services-bg.png), linear-gradient(#171717, #171717)
    }
} */

/* footer.four {
    background-image: url(../../Assets/images/footer/footer-bg.png), linear-gradient(#1e1c22, #1e1c22)
} */

/* @media(max-width:767px) {
    footer.four {
        background-image: url(../../Assets/images/footer/home5-services-bg.png), linear-gradient(#171717, #171717)
    }
} */

/* @media(max-width:767px) {
    footer {
        background-image: url(../../Assets/images/footer/home5-services-bg.png), linear-gradient(#171717, #171717)
    }
} */

footer .footer-top {
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    margin-bottom: 80px
}

footer .footer-top .footer-top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0
}

@media(max-width:576px) {
    footer .footer-top .footer-top-content {
        flex-wrap: wrap;
        gap: 20px
    }
}

footer .footer-top .footer-top-content .footer-contect {
    display: flex;
    align-items: center;
    gap: 10px
}

footer .footer-top .footer-top-content .footer-contect .icon svg {
    fill: #f5bb0c;
}

footer .footer-top .footer-top-content .footer-contect .content span {
    /* font-family: 'Saira', sans-serif; */
    font-weight: 400;
    font-size: 13px;
    letter-spacing: .03em;
    color: #fff;
    display: inline-block;
    margin-bottom: 3px
}

footer .footer-top .footer-top-content .footer-contect .content h6 {
    margin-bottom: 0
}

footer .footer-top .footer-top-content .footer-contect .content h6 a {
    /* font-family: 'Saira', sans-serif; */
    font-weight: 500;
    font-size: 18px;
    letter-spacing: .03em;
    color: #fff;
    transition: .5s
}

footer .footer-top .footer-top-content .footer-contect .content h6 a:hover {
    color: #f5bb0c
}

footer .footer-widget .footer-contact {
    max-width: 300px;
    width: 100%;
    margin: 0 auto
}

footer .footer-widget .footer-contact h4 {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    /* font-family: 'Saira', sans-serif; */
    font-weight: 500;
    font-size: 23px;
    letter-spacing: .03em;
    color: #fff
}

footer .footer-widget .footer-contact h4 svg {
    fill: #f5bb0c
}

footer .footer-widget .footer-contact h6 {
    /* font-family: 'Saira', sans-serif; */
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .02em;
    color: #fff;
    margin-bottom: 20px
}

footer .footer-widget .footer-contact a {
    /* font-family: 'Saira', sans-serif; */
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    transition: .5s
}

footer .footer-widget .footer-contact a:hover {
    color: #f5bb0c
}

footer .footer-widget .footer-contact .form-inner {
    display: flex;
    align-items: center
}

footer .footer-widget .footer-contact .form-inner input {
    width: 100%;
    background: rgba(27, 31, 35, .8);
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 5px 0 0 5px;
    /* font-family: 'Saira', sans-serif; */
    font-weight: 400;
    font-size: 13px;
    color: #a4a4a4;
    height: 50px;
    padding: 25px 20px
}

footer .footer-widget .footer-contact .form-inner button {
    height: 50px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: #f5bb0c;
    border-radius: 0 5px 5px 0;
    transition: .5s
}

footer .footer-widget .footer-contact .form-inner button svg {
    stroke: #1d1d1d;
    transition: .5s
}

footer .footer-widget .footer-contact .form-inner button:hover {
    background-color: #fff
}

footer .footer-widget .widget-title {
    margin-bottom: 25px
}

footer .footer-widget .widget-title h4 {
    /* font-family: 'Saira', sans-serif; */
    font-weight: 600;
    font-size: 25px;
    letter-spacing: .03em;
    color: #fff;
    margin-bottom: 0
}

footer .footer-widget .menu-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer .footer-widget .menu-container ul li {
    margin-bottom: 18px
}

footer .footer-widget .menu-container ul li:last-child {
    margin-bottom: 0
}

footer .footer-widget .menu-container ul li a {
    /* font-family: 'Saira', sans-serif; */
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .02em;
    color: #b4b4b4;
    transition: .5s
}

footer .footer-widget .menu-container ul li a:hover {
    color: #f5bb0c
}

footer .footer-btm {
    border-top: 1px solid rgba(255, 255, 255, .08);
    margin-top: 80px
}

footer .footer-btm .footer-btn-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0
}

@media(max-width:767px) {
    footer .footer-btm .footer-btn-content {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center
    }
}

footer .footer-btm .footer-btn-content .copyright-area p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    color: #e4e4e4
}

@media(max-width:767px) {
    footer .footer-btm .footer-btn-content .copyright-area p {
        text-align: center
    }
}

footer .footer-btm .footer-btn-content .copyright-area p a {
    color: #fff;
    font-weight: 700;
    transition: .5s
}

footer .footer-btm .footer-btn-content .copyright-area p a:hover {
    color: #f5bb0c
}