.contact-page-wrap {
    padding: 120px 0;
}

.contact-page-wrap .contact-content>span {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #f5bb0c;
    display: inline-block
}

.contact-page-wrap .contact-content h2 {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 42px;
    line-height: 1.4;
    letter-spacing: .03em;
    text-transform: capitalize;
    color: #FFF;
}

@media(max-width:767px) {
    .contact-page-wrap .contact-content h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .contact-page-wrap .contact-content h2 {
        font-size: 30px
    }
}

.contact-page-wrap .contact-content p {
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 0
}

.contact-page-wrap .contact-content .informations {
    padding-top: 50px
}

.contact-page-wrap .contact-content .informations .single-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 15px
}

.contact-page-wrap .contact-content .informations .single-info .icon {
    height: 2.8rem;
    min-width: 2.8rem;
    border-radius: 50%;
    border: 1px solid #f5bb0c;
    color: #f5bb0c;
    display: flex;
    align-items: center;
    justify-content: center
}

.contact-page-wrap .contact-content .informations .single-info .icon i {
    font-size: 20px
}

.contact-page-wrap .contact-content .informations .single-info .info a {
    font-size: 16px;
    font-weight: 400;
    color: #b4b4b4;
    display: block;
    transition: all .5s ease
}

.contact-page-wrap .contact-content .informations .single-info .info a:hover {
    color: #f5bb0c;
}

.contact-page-wrap .contact-content .informations .single-info .info p {
    font-size: 16px;
    font-weight: 400;
    color: #b5b5b5;
    display: block;
    transition: all .5s ease
}

.follow-area {
    padding: 30px 0
}

.follow-area h5 {
    font-size: 22px;
    font-weight: 700;
    color: #FFF;
    position: relative;
    margin-bottom: 0
}

.follow-area .para {
    font-size: 16px;
    font-weight: 400;
    color: #b5b5b5;
    font-family: poppins, sans-serif;
    line-height: 28px;
    margin-bottom: 10px
}

.follow-area .blog-widget-body .follow-list {
    list-style: none;
    margin: 0;
    padding: 10px 0 0;
}

.follow-area .blog-widget-body .follow-list li a {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    background: 0 0;
    border-radius: 3px;
    font-size: 18px;
    color: #fff;
    transition: all .35s ease-in-out
}

.follow-area .blog-widget-body .follow-list li a:hover {
    background-color: #f5bb0c;
    border-color: #f5bb0c;
}

.contact-page-wrap .contact-form-wrap {
    background-image: url(../../Assets/images/contactUs/pricing-bg.png), linear-gradient(#161A1E, #161A1E);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 10px
}

@media(min-width:992px) {
    .contact-page-wrap .contact-form-wrap {
        margin-left: 25px
    }
}

.contact-page-wrap .contact-form-wrap .form-tltle {
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, .05)
}

.contact-page-wrap .contact-form-wrap .form-tltle h5 {
    font-weight: 500;
    font-size: 22px;
    color: #fff;
    margin-bottom: 0;
    text-align: center
}

.contact-page-wrap .contact-form-wrap .contact-form {
    padding: 15px 30px
}

@media(max-width:1399px) {
    .contact-page-wrap .contact-form-wrap .contact-form {
        padding: 40px 25px
    }
}

.contact-page-wrap .contact-form-wrap .contact-form form label {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 8px
}

form .p-dropdown {
    background: rgba(255, 255, 255, .04);
    border: 1px solid rgba(255, 255, 255, .05) !important;
    border-radius: 5px !important;
}

.p-dropdown-panel .p-dropdown-header {
    /* background: #1e2226 !important; */
}

.p-dropdown-panel {
    /* background: #1e2226 !important; */
}

.p-dropdown .p-inputtext {
    /* color: #FFF; */
}

.p-dropdown-filter-container .p-inputtext {
    background: transparent;
}

.contact-page-wrap .contact-form-wrap .contact-form form input,
.contact-page-wrap .contact-form-wrap .contact-form form textarea {
    width: 100%;
    background: rgba(255, 255, 255, .04);
    border: 1px solid rgba(255, 255, 255, .05);
    border-radius: 5px;
    height: 41px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .02em;
    color: #dedada;
    padding: 7px 15px
}

.contact-page-wrap .contact-form-wrap .contact-form form textarea {
    height: 100px
}

.contact-page-wrap .contact-form-wrap .contact-form form .primary-btn3 {
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    border: none;
}