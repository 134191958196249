/* =Sitemap Page CSS =================================================================*/
.sitemap-box {
    position: relative;
    padding: 20px 0 20px 40px;
    margin-bottom: 30px;
    z-index: 1;
}

.sitemap-box:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    z-index: -1;
    opacity: 0.5;
}

.sitemap-box:nth-child(1n+1):before {
    background: -moz-linear-gradient(45deg, rgba(245, 4, 0, 1) 0%, rgba(250, 187, 85, 1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(245, 4, 0, 1)), color-stop(100%, rgba(250, 187, 85, 1)));
    background: -webkit-linear-gradient(45deg, rgba(245, 4, 0, 1) 0%, rgba(250, 187, 85, 1) 100%);
    background: -o-linear-gradient(45deg, rgba(245, 4, 0, 1) 0%, rgba(250, 187, 85, 1) 100%);
    background: -ms-linear-gradient(45deg, rgba(245, 4, 0, 1) 0%, rgba(250, 187, 85, 1) 100%);
    background: linear-gradient(45deg, rgba(245, 4, 0, 1) 0%, rgba(250, 187, 85, 1) 100%);
}

.sitemap-box:nth-child(2n+2):before {
    background: -moz-linear-gradient(45deg, rgba(62, 199, 139, 1) 0%, rgba(29, 232, 134, 1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(62, 199, 139, 1)), color-stop(100%, rgba(29, 232, 134, 1)));
    background: -webkit-linear-gradient(45deg, rgba(62, 199, 139, 1) 0%, rgba(29, 232, 134, 1) 100%);
    background: -o-linear-gradient(45deg, rgba(62, 199, 139, 1) 0%, rgba(29, 232, 134, 1) 100%);
    background: -ms-linear-gradient(45deg, rgba(62, 199, 139, 1) 0%, rgba(29, 232, 134, 1) 100%);
    background: linear-gradient(45deg, rgba(62, 199, 139, 1) 0%, rgba(29, 232, 134, 1) 100%);
}

.sitemap-box:nth-child(3n+3):before {
    background: -moz-linear-gradient(45deg, rgba(44, 194, 232, 1) 0%, rgba(54, 216, 208, 1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(44, 194, 232, 1)), color-stop(100%, rgba(54, 216, 208, 1)));
    background: -webkit-linear-gradient(45deg, rgba(44, 194, 232, 1) 0%, rgba(54, 216, 208, 1) 100%);
    background: -o-linear-gradient(45deg, rgba(44, 194, 232, 1) 0%, rgba(54, 216, 208, 1) 100%);
    background: -ms-linear-gradient(45deg, rgba(44, 194, 232, 1) 0%, rgba(54, 216, 208, 1) 100%);
    background: linear-gradient(45deg, rgba(44, 194, 232, 1) 0%, rgba(54, 216, 208, 1) 100%);
}

.sitemap-box:nth-child(4n+4):before {
    background: -moz-linear-gradient(45deg, rgba(107, 94, 224, 1) 0%, rgba(102, 159, 255, 1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(107, 94, 224, 1)), color-stop(100%, rgba(102, 159, 255, 1)));
    background: -webkit-linear-gradient(45deg, rgba(107, 94, 224, 1) 0%, rgba(102, 159, 255, 1) 100%);
    background: -o-linear-gradient(45deg, rgba(107, 94, 224, 1) 0%, rgba(102, 159, 255, 1) 100%);
    background: -ms-linear-gradient(45deg, rgba(107, 94, 224, 1) 0%, rgba(102, 159, 255, 1) 100%);
    background: linear-gradient(45deg, rgba(107, 94, 224, 1) 0%, rgba(102, 159, 255, 1) 100%);
}

.sitemap-box h3 {
    font-size: 25px;
    font-weight: bold;
}

.sitemap-box h3:last-child {
    margin-bottom: 0;
}

.sitemap-box h3 a {
    color: #18283b;
}

.sitemap-box h3 a:hover {
    color: #f5bb0c;
    text-decoration: none;
}

.sitemap-box ul {
    margin: 0;
    padding: 0;
    padding-left: 40px;
    list-style: none;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.sitemap-box ul li {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    margin-bottom: 10px;
    position: relative;
    padding: 0 15px;
}

.sitemap-box ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    height: 5px;
    width: 5px;
    background: #6b7a8c;
    border-radius: 50%;
}

.sitemap-box ul li a {
    color: #6b7a8c;
}

.sitemap-box ul li a:hover {
    color: #f5bb0c;
    text-decoration: none;
}