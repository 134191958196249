.home-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;
}

.centered-text h1 {
    font-size: 4rem;
    margin: 0;
}

.centered-text p {
    font-size: 1.5rem;
    margin: 0;
}