.breadcrumbs {
    position: relative;
    background-image: url(../../Assets/images/Breadcrumb/breadcrumb-page-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1
}

.breadcrumbs .inner-banner-1 {
    border-radius: 130px;
    position: absolute;
    top: 115px;
    left: 45px
}

@media(max-width:991px) {
    .breadcrumbs .inner-banner-1 {
        display: none;
        visibility: hidden
    }
}

.breadcrumbs .inner-banner-1::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 130px;
    background: rgba(29, 29, 29, .6)
}

.breadcrumbs .inner-banner-1 img {
    border-radius: 130px
}

.breadcrumbs .inner-banner-2 {
    position: absolute;
    bottom: 0;
    right: 170px;
    border-radius: 130px 130px 0 0
}

@media(max-width:991px) {
    .breadcrumbs .inner-banner-2 {
        display: none;
        visibility: hidden
    }
}

.breadcrumbs .inner-banner-2::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 130px 130px 0 0;
    background: rgba(29, 29, 29, .6)
}

.breadcrumbs .inner-banner-2 img {
    border-radius: 130px 130px 0 0
}

.breadcrumbs .breadcrumb-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 150px 0 80px;
    /* padding: 205px 0 140px; */
}

@media(max-width:576px) {
    .breadcrumbs .breadcrumb-wrapper {
        padding: 155px 0 90px
    }
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt {
    position: relative;
    text-align: center
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt>span {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: #b5b5b5;
    display: inline-block;
    padding: 8px 40px;
    position: relative;
    background-color: rgba(6, 216, 137, .2);
    margin-bottom: 10px
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt>span::after {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%)
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt>span::before {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%)
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt h1 {
    font-size: 54px;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 15px
}

@media(max-width:576px) {
    .breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt h1 {
        font-size: 40px
    }
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt .breadcrumb-list {
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt .breadcrumb-list a {
    color: #f5bb0c;
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt .breadcrumb-list i {
    margin: 0 5px
}