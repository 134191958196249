.terms-list li h3 {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin-bottom: 8px;
}

.terms-list li p {
    color: #555;
    line-height: 1.7;
}