/* App.css */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');
/* @import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: cadetblue; */
}

::selection {
  background-color: #f5bb0c;
  color: #000000;
}

a {
  text-decoration: none !important;
}

/* span {
  color: #f5bb0c;
} */

.parallax-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.section {
  padding: 50px 20px;
  text-align: center;
}

.section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.section p {
  font-size: 1.2em;
}

.section ul {
  list-style: none;
  padding: 0;
}

.section ul li {
  font-size: 1.2em;
  margin: 10px 0;
}

/* App.css */
.scroll-content {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

/* Hide scrollbar */
.scroll-content .scrollbar-track {
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show scrollbar when scrolling */
.scroll-content.scroll-active .scrollbar-track {
  opacity: 1;
}

/* Ensure smooth-scrollbar styles are applied */
.scroll-content .scrollbar-track-x,
.scroll-content .scrollbar-track-y {
  z-index: 10;
}

/* Change track color */
.scroll-content .scrollbar-track {
  background-color: rgba(243, 69, 69, 0.2);
  /* Change this to your desired color */
}

/* Change thumb color */
.scroll-content .scrollbar-thumb {
  background-color: rgba(218, 16, 16, 0.767);
  /* Change this to your desired color */
}