.bg-color {
    background: #0f0f0f;
}

.home6-solution-section {
    /* background: #1e1c22; */
    padding: 100px 0
}

@media(max-width:991px) {
    .home6-solution-section {
        padding: 80px 0
    }
}

.home6-solution-section .single-solution {
    position: relative;
    border-radius: 5px;
    z-index: 1;
    padding: 30px 30px 50px;
    overflow: hidden;
    transition: .5s;
    /* min-height: 26rem; */
}

.home6-solution-section .single-solution::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../Assets/images/services/services-bg.png), linear-gradient(#161519, #161519);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    transition: .5s
}

.home6-solution-section .single-solution .background-img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -2;
    transition: .5s;
    opacity: 0
}

.home6-solution-section .single-solution .background-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.home6-solution-section .single-solution .background-img::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 24, 28, .75);
    transition: .5s
}

.home6-solution-section .single-solution .sl h2 {
    font-weight: 700;
    font-size: 50px;
    letter-spacing: .03em;
    /* color: rgba(255, 255, 255, .08); */
    color: rgba(255, 255, 255, .2);

    /* color:white; */
    margin-bottom: 0
}

.home6-solution-section .single-solution .solution-content h3 {
    margin-bottom: 13px
}

.home6-solution-section .single-solution .solution-content h3 a {
    font-weight: 600;
    font-size: 27px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #fff
}

.home6-solution-section .single-solution .solution-content p {
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 30px;
}

.home6-solution-section .single-solution .solution-btn-icon {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}

.home6-solution-section .single-solution .icon {
    position: absolute;
    right: 30px;
    bottom: 20px;
    transition: .5s;
    transform: scale(1);
    transform-origin: right bottom
}

.home6-solution-section .single-solution .icon svg {
    fill: #f5bb0c
}

.home6-solution-section .single-solution .icon .blure {
    position: absolute
}

.home6-solution-section .single-solution .icon::after {
    content: "";
    background-color: rgba(233, 195, 45, 0.1);
    position: absolute;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    bottom: -78px;
    right: -55px
}

.home6-solution-section .single-solution:hover::after {
    opacity: 0
}

.home6-solution-section .single-solution:hover .background-img {
    opacity: 1
}

.home6-solution-section .single-solution:hover .icon {
    opacity: 0;
    transform: scale(0)
}

.home6-solution-section .single-solution:hover .sl h2 {
    /* color: rgba(255, 255, 255, .2); */
    color: #ffffff80;
}

.home6-solution-section .single-solution:hover .solution-content h3 a {
    color: #fff
}

.home6-solution-section .single-solution:hover .solution-content p {
    color: rgba(255, 255, 255, .7)
}

.home6-solution-section .swiper-pagination61 {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    gap: 15px
}

@media(max-width:767px) {
    .home6-solution-section .swiper-pagination61 {
        display: none;
        visibility: hidden
    }
}

.home6-solution-section .swiper-pagination61 .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 1px solid #f5bb0c;
    opacity: 1;
    position: relative
}

.home6-solution-section .swiper-pagination61 .swiper-pagination-bullet::after {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #f5bb0c;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0
}

.home6-solution-section .swiper-pagination61 .swiper-pagination-bullet-active {
    border-color: #f5bb0c
}

.home6-solution-section .swiper-pagination61 .swiper-pagination-bullet-active::after {
    opacity: 1
}

.service-title:hover {
    color: #f5bb0c !important;
}

.primary-btn9 {
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: #f5bb0c
}

.primary-btn9 span {
    text-decoration: underline
}

.primary-btn9 svg {
    stroke: #f5bb0c;
    transition: .5s
}

.primary-btn9:hover {
    color: #fff
}

.primary-btn9:hover span {
    color: #fff
}

.primary-btn9:hover svg {
    stroke: #fff
}

.solution-content a {
    text-decoration: none;
}