.side-bar-menu {
    position: sticky;
    display: flex;
    height: 100vh;
    top: 0;
    z-index: 10;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.16);
}

.menu-header {
    padding: 10px 0px;
    color: #f5bb0c;
    margin-left: 1.1rem;
    font-size: 11.49px;
}

.menu-icon {
    background-color: transparent !important;
}

/* style for hover color effect for menu and submenu */
.ps-menu-button:hover {
    color: white !important;
    background-color: #f5bb0c !important;
}

.menu-item.active {
    color: white;
    background-color: transparent;
}

.custom-menu-item {
    font-size: 0.8125rem;
    font-weight: 500;
    color: white;
    background: #002433;
}

/* style for submenu and item background color */
.custom-submenu {
    color: white;
    background-color: #002433;
}