.home3-success-stories-area {
    background: black !important;
    padding: 100px 8% !important
}

.home3-success-stories-area.two {
    background-color: transparent;
    padding: 0 8%
}

@media(max-width:1700px) {
    .home3-success-stories-area.two {
        padding: 0 3%
    }
}

@media(max-width:1199px) {
    .home3-success-stories-area.two {
        padding: 0 0%
    }
}

@media(max-width:1700px) {
    .home3-success-stories-area {
        padding: 100px 3%
    }
}

@media(max-width:1199px) {
    .home3-success-stories-area {
        padding: 100px 0%
    }
}

.home3-success-stories-area .swiper-slide:nth-child(even) {
    margin-top: 30px
}

@media(max-width:1199px) {
    .home3-success-stories-area .swiper-slide:nth-child(even) {
        margin-top: 0
    }
}

.home3-success-stories-area .success-storie-card {
    position: relative;
    border-radius: 5px;
    transition: .5s
}

.home3-success-stories-area .success-storie-card .success-img {
    border-radius: 5px
}

.home3-success-stories-area .success-storie-card .success-img img {
    border-radius: 5px;
    width: 100%
}

.home3-success-stories-area .success-storie-card .success-content {
    position: absolute;
    z-index: 1;
    left: 25px;
    right: 25px;
    bottom: 25px;
    background-image: url(../../Assets/images/products/success-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 35px 25px;
    border-radius: 5px
}

@media(max-width:1399px) {
    .home3-success-stories-area .success-storie-card .success-content {
        padding: 35px 20px
    }
}

@media(max-width:1399px) {
    .home3-success-stories-area .success-storie-card .success-content {
        padding: 25px 20px
    }
}

.home3-success-stories-area .success-storie-card .success-content span {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .05em;
    text-transform: capitalize;
    color: #b5b5b5;
    display: inline-block;
    position: relative;
    margin-bottom: 15px
}

.home3-success-stories-area .success-storie-card .success-content span::after {
    content: "";
    height: 1px;
    width: 100%;
    background: #f5bb0c;
    border-radius: 5px;
    position: absolute;
    left: 0;
    bottom: -3px
}

.home3-success-stories-area .success-storie-card .success-content h3 {
    margin-bottom: 0
}

.home3-success-stories-area .success-storie-card .success-content h3 a {
    font-weight: 500;
    font-size: 26px;
    line-height: 1.4;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #fff;
    transition: .5s
}

@media(max-width:1399px) {
    .home3-success-stories-area .success-storie-card .success-content h3 a {
        font-size: 24px
    }
}

.home3-success-stories-area .success-storie-card .success-content h3 a:hover {
    color: #f5bb0c;
}

.home3-success-stories-area .success-storie-card .success-content .view-btn {
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%)
}

.home3-success-stories-area .success-storie-card .success-content .view-btn a {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: #f5bb0c;
    display: flex;
    align-items: center;
    justify-content: center
}

.home3-success-stories-area .success-storie-card .success-content .view-btn a svg {
    stroke: #1b1f23;
    transform: rotate(90deg);
    transition: .5s
}

.home3-success-stories-area .success-storie-card:hover .success-content .view-btn a svg {
    transform: rotate(45deg)
}

.home3-success-stories-area .swiper-pagination11 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: unset !important;
    bottom: unset !important;
    display: flex;
    gap: 15px
}

@media(max-width:767px) {
    .home3-success-stories-area .swiper-pagination11 {
        display: none;
        visibility: hidden
    }
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .5);
    opacity: 1;
    position: relative
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet::after {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #f5bb0c;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet-active {
    border-color: #f5bb0c;
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet-active::after {
    opacity: 1
}


/* pagination */

/* .pagination {
    padding-top: 60px;
    gap: 20px
}

.pagination .page-item.disabled .page-link {
    background-color: transparent;
    background-color: transparent;
    border: 1px solid #f5bb0c;
    color: #f5bb0c;
}

.pagination li {
    padding: 0
}

.pagination li a {
    padding: 0;
    height: 34px;
    width: 34px;
    background-color: transparent;
    border: 1px solid #f5bb0c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    color: #f5bb0c;
    transition: .5s
}

.pagination li a.active {
    color: #1D1D1D;
    background-color: #f5bb0c;
    border-color: #f5bb0c
}

.pagination li a:hover {
    color: #fff;
    background-color: #f5bb0c;
    border-color: #f5bb0c
} */